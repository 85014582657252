.custom-file-upload input[type="file"] {
  display: none;
}
.txt-box{
  font-size: 15px;
  margin: auto;
}
.custom-file-upload {
  border-radius: 10px;
  display: inline-block;
  position: relative;
  padding: 6px;
  width: 100%;
  border: 1px solid #ccc;
  cursor: pointer;
}
.img-wrap{
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.img-upload:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63D3A6;
  width: 100%;
  height: auto;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.label-1{
  font-weight: 500;
  font-size: 14px;
}
.label-2{
  font-size: 14px;
  color: #6C757D;
  font-weight: 500;
}
.ImgWrapper{
  margin: auto;
  text-align: center;
}
.ImgIcon{
  width: 40px;
}
.previewWrapper video{
    width: 100%;
}
.input-color{
  width: 100px !important;
  margin-left: 10px !important;
}
.input-color .MuiInputBase-formControl {
    border-radius: 0px !important;
}
.input-color input{
  border: 1px solid #ccc;
  border-radius: 6px !important;
  padding: 0px;
  height: 42px;
}
.text-para{
  font-size: 14px;
  font-weight: 500;
  color: #444;
}
.previewWrapper{
  background: #000;
  padding: 10px;
}
.previewContentBox{
  background-color: #3F3F46;
  padding-bottom: 8px;
  margin-top: -10px;
}
.previewBoxBrand{
  background-color: #3F3F46;
  padding-bottom: 8px;
}
.adPreviewLogo{
  width: 35px;
  height: 35px;
  padding: 2px;
  margin: 7px;
  object-fit: contain;
  background: #fff;
  border-radius: 3px;
}
.adPreviewLogo-sm{
  width: 25px;
  height: 25px;
  padding: 2px;
  margin: 7px;
  object-fit: contain;
  background: #fff;
  border-radius: 3px;
}
.ad-box{
background: #F0D822;
font-size: 10px;
padding: 1px 4px;
border-radius: 3px;
}
.brand-name{
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
}
.brand-img-box{
padding: 4px 0px;
}
.campaign-title{
  line-height: 14px;
  font-size: 12px;
  color: #F2F2F2;
  font-weight: 500;
  text-transform: capitalize;
}
.content-box-grid{
  padding-bottom: 5px;
  margin-top: 0px;
}
.preview-tle-1{
color: rgba(245, 245, 245, 0.74);
  font-size: 10px;
  opacity: 0.9;
  font-weight: 500;
  text-align: center;
}
.preview-tle-2{
color: whitesmoke;
font-size: 11.5px;
opacity: 0.9;
font-weight: 600;
line-height: 11px;
text-align: center;
}
.border-right{
border-right: 1px solid #89898D;
}
.ad-button-box{
padding: 6px;
}
.ad-button-box button{
width: 100%;
  height: 30px;
  line-height: 15px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  border: 1px;
}
.star-icon{
margin-top: -2px;
  font-size: 10px !important;
}
.static-img-box{
padding: 15px 10px;
}
.static-img-box img{
width: 100%;
border-radius: 7px;
object-fit: contain;
}
.hotstar-logo-install{
  position: absolute;
  width: 35px;
  right: 5.3%;
  top: 5%;
  z-index: 9;
}
.hotstar-logo{
  position: absolute;
  width: 35px;
  right: 5.3%;
  top: 5.6%;
  z-index: 9;
}
.hotstar-logo-mw{
  position: absolute;
  width: 35px;
  right: 5.3%;
  top: 4.5%;
  z-index: 9;
}
.hotstar-logo-cards{
  position: absolute;
  width: 35px;
  right: 5.3%;
  top: 4.5%;
  z-index: 9;
}
.hotstar-logo-ctv {
  position: absolute;
  width: 35px;
  right: 5.3%;
  top: 11.6%;
  z-index: 9;
}
.hotstar-logo-mwc {
  position: absolute;
  width: 35px;
  right: 5.3%;
  top: 6.6%;
  z-index: 9;
}
.headline-box{
  padding: 0px 7px;
}
.branded-headline-box{
  padding: 0px 7px;
  margin-top: -7px;
}
.headline-box p, .branded-headline-box p{
    color: #DEE2E6;
    font-size: 12px;
    text-transform: capitalize;
}
.mwc-button-box {
    margin: 10px 0px;
    text-align: right;
    padding-right: 10px;
}
.mwc-button-box button{
    font-size: 13px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
.mw-button-box{
  margin: 10px 0px;
    text-align: right;
    padding-right: 10px;
}
.mw-button-box button{
    background: transparent !important;
    color: #0D6EFD;
    border: 1.5px solid #0D6EFD;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.branded-button-box {
  margin: 10px 0px;
  text-align: right;
  padding-right: 10px;
  margin-bottom: 0px;
}
.branded-button-box button{
  background: transparent !important;
  color: #0D6EFD;
  border: 1.5px solid #0D6EFD;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2px 10px !important;
  margin-bottom: 10px;
}
.carousel-img{
    margin-top: 15px;
    width: 100%;
    padding: 7px;
    border-radius: 15px;
}
.carousel-p{
    padding: 10px;
    color: #fff;
    padding-top: 0px;
    font-size: 12px;
}
.staticBrandImg{
  width: 100%;
}
.astons-img-box img{
  width: 100%;
}
.width100{
  width: 100%;
}
#demo-simple-select-label {
  /* top: -4px !important; */
}