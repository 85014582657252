
th, td, tr, tr .MuiBox-root, td .MuiBox-root , th .MuiBox-root{
    color: #000 !important;
    text-align: center;
}

th, th .MuiBox-root{
    padding: 15px 3px !important;
}

#excelTable th, #excelTable th .MuiBox-root {
    padding: 0px 10px !important;
    line-height: 11px;
}

.table-default {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
}

.table-select{
    padding: 5px;
    border-color: #d2d6da;
    border-radius: 6px;
}

.table-select .MuiSelect-select {
    height: 20px !important;
}

.table-default th, .table-default th .MuiBox-root{
    padding-left: 10px;
    padding-right: 3px;
    text-align: left;
    position: relative;
    opacity: 0.7;
    background: transparent;
    color: #7b809a;
    border-radius: none;
    box-shadow: none;
    font-size: 0.65rem;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 0.0625rem solid #f0f2f5;
    text-align: center;
}

.table-container{
    width: 100%;
    overflow-x: auto;
    background-color: #ffffff;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%);
    border-radius: 0.75rem;
    box-shadow: none;
    
}

.table-default td, .table-default tr, .table-default tr .MuiBox-root, .table-default td .MuiBox-root {
    /* text-align: left; */
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left:3px;
    padding-right: 3px;
    opacity: 1;
    background: transparent;
    color: #344767;
    border-radius: none;
    box-shadow: none;
    font-size: 12px;
    border-bottom: 0.0625rem solid #f0f2f5;
}

.td-content{
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    opacity: 1;
    background: transparent;
    border-radius: none;
    box-shadow: none;
}

.primary-text{
    color:#0a76bc !important;
}

label{
    z-index: auto !important;
}

.form-wrapper{
    padding: 20px 10%;
}
.MuiTextField-root {
    width: 100%;
}

input[type="file"] ,input[type="date"], input[type="select"]{
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none !important;
}

input[type="file"]:hover ,input[type="date"]:hover, input[type="select"]:hover{
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none !important;
}
input[type="file"]:focus ,input[type="date"]:focus, input[type="select"]:focus, input[type="color"]:focus ,
input[type="file"]:visited ,input[type="date"]:visited, input[type="select"]:visited, input[type="color"]:focus,
fieldset:visited{
    border: 2px solid #0d6efd;
    border-radius: 6px;
    outline: none !important;
}
.react-date-picker__wrapper{
    border: none !important;
}


.MuiSelect-select{
    height: 44px !important;
}

.bag-container .td-content .MuiSelect-select {
    height: 30px !important;
    text-transform: capitalize;
    padding: 0px !important;
}

.bag-container .MuiPagination-ul{
     justify-content: center;
}

.bag-container .MuiPaginationItem-root.Mui-selected {
    color: #fff;
    border: 1px solid rgba(25, 118, 210, 0.5);
    background-color: rgba(25, 118, 210, 0.12);
}
.bag-container .MuiPaginationItem-root.Mui-selected:hover {
    color: #fff;
    border: 1px solid rgba(25, 118, 210, 0.5);
    background-color: rgba(25, 118, 210, 0.12);
}
.bg-image-wrapper{
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    top: 0px;
    background: transparent;
}

header{
    background-color: #fff !important;
    padding: 0px !important;
    min-height: 30px !important;
    top: 0px !important;
    margin-bottom: 10px;
}

.bg-white{
    padding:  15px !important;
    border-radius: 6px;
     overflow: visible;
}

.terms-text{
    line-height: 10px;
    font-size: 13px;
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: #ccc !important;
    border: 0;
    opacity: .25;
}

.box-container{
    padding: 15px;
    background: #fff;
    box-shadow: inset 0rem 0rem 0.0625rem 0.0625rem rgb(255 255 255 / 90%), 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
    -webkit-backdrop-filter: saturate(200%);
    border-radius: 10px;
}

.MuiSelect-icon {
    display: block !important;
    font-size: 20px !important;
}

.pull-right{
    float:  right;
}

.pull-left{
    float:  left;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}
.modal-width{
    width: auto !important;
}

.text-sm{
    font-size: 14px !important;
    width: 100%;
}

.text-xs{
    font-size: 11px !important;
}

.text-normal{
    font-size: 14px !important;
}

.MuiInputLabel-shrink {
    background-color: #fff;
}

label{
    overflow: visible !important;
}

textarea:focus, input:focus{
    outline: none !important;
}

.MainContainer{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
}

.modal-body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #fff;
    box-shadow: 24px;
    padding: 4px;
}

/* Error bagde color */

.status {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.bg-pending{
    background-color: #000 !important;
}
.bg-approved{
    background-color: #198038 !important;
}
.bg-in-draft{
    background-color: #6f6f6f !important;
}
.bg-submitted{
    background-color: #0f62fe !important;
}
.bg-in-review{
    background-color: #eb6200 !important;
}
.bg-qc-rejected{
    background-color: #da1e28 !important;
}
.MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff;
    background-color: #0977bc !important;
    font-weight: 500;
    font-size: 14px;
}
.MuiPaginationItem-page  {
    font-weight: 500 !important;
    font-size: 14px !important;
}

.MuiLinearProgress-root{
    overflow: hidden !important;
}
@media (max-width: 786px) {
    
    .modal-body{
        width: 90%;
    }

    .abc{
        padding: 10px !important;
    }
    .MuiCardContent-root{
        padding: 10px !important;
    }

    .MuiTypography-h4 {
        font-size: 20px !important;
        padding: 0px 10px;
    }

    label{
        font-size: 12px !important;
    }

    .text-sm {
        font-size: 13px !important;
        width: 100%;
    }

    .ImgWrapper{
        margin: 10px 1px !important;
    }

    .text-para {
        font-size: 12px !important;
    }
    .btn, button{
        font-size: 12px !important;
    }

    .MainContainer{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


.ck-button {
    background-color:#EFEFEF;
    border-radius:2px;
    overflow:auto;
    float:left;
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
}

.ck-button label {
    float:left;
   
}
.ck-button button{
    padding: 0px;
}
.ck-button label span {
    text-align:center;
    display:block;
    width: max-content;
    cursor: pointer;
    font-size: 12px;
    padding: 5px 15px;
}

.ck-button label input {
    position:absolute;
    left:-100px;
}

.ck-button input:checked + span {
    background-color:#267eea;
    color:#fff;
}
.ck-button:hover {
    background:#267eea;
}

.ck-button-list ul{
    list-style: none;
    padding: 20px 10px;
    position: absolute;
    background: #fff;
    z-index: 9;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 20%);
    min-width: 150px;
}

.ck-button-list ul li button{
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
}

.td-content input{
    padding: 5px !important;
    font-size: 12px;
}
.td-content fieldset{
    top: 0px;
}
.discount-input-table{
    width: 50px;
}

.discount-input-table legend, .td-content legend{
    float: left;
}

.three-dots-menu{
    font-size: 22px;
    cursor: pointer;
}

#excelTable{
    display: none;
}

#excelTable div ul{
    list-style: none;
    padding: 0px;
}

#excelTable div ul li{
    font-size: 10px;
    color: #000;
}

.header-top {
    padding: 5px 15px !important;
    border-radius: 0px;
}

.header-top .toggle-btn:hover{
    border-radius: 50%;
    box-shadow: 0px 0px 3px 0px #ccc;
}
.breadcrumbs-custom{
    margin: 0px !important;
    flex-basis: 90%;
}

.MuiPaper-rounded{
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 7px !important;
}

.MuiGrid-item .MuiPaper-rounded{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
}

.MuiGrid-container {
    margin-bottom: 10px;
}

.custom-input label{
    line-height: 5px;
    font-size: 12px;
}

.custom-input .MuiOutlinedInput-root{
    height: 35px;
}
/* .MuiOutlinedInput-root fieldset{
    border-width: 0px !important;
} */

.custom-list .MuiListItemText-root{
    opacity: 1 !important;
}

.custom-list li .MuiBox-root{
    border-bottom: 0.2px solid #ddd;
    border-radius: 0px;
}

.input-width-75{
    width:75%
}

input[type="date"] {
    border: 0;
}


input[type="date"]:hover, input[type="date"]:focus {
    border: 0;
}

/* .clone-btn{
    border: none !important;
    padding: 0px 0px !important;
    background-color: #fff !important;
    line-height: 10px !important;
    border-radius: 0px !important;
    margin-top: -12px;
    margin-right: 15px;

}
.clone-btn:hover{
    box-shadow: 1px 1px 1px 0px #444
}

.clone-icon{
    background: #0e6ffd;
    padding: 6px 7px;
    display: inline-flex;
}
.delete-icon{
    background: #fd0e0e;
    padding: 6px 7px;
    display: inline-flex;
}
.clone-btn svg{
    font-size: 11px !important;
}
.clone-text{
    font-size: 12px;
    color: #000;
    padding: 0px 6px 0px 2px;
}
.clone-btn span{
    font-size: 13px;
}

.delete-btn{
    border: none !important;
    padding: 0px !important;
    margin-right: 10px;
}

.delete-btn span{
    font-size: 13px;
}*/


 .table-heading-btn{
    display: inline-block;
    float: right;
 } 
 .clone-btn{
    border: none !important;
    background-color: #fff !important;
    line-height: 15px !important;
    border-radius: 0px !important;
    margin-right: 15px;
    border: 1px solid #fff !important;
    padding: 2px 7px !important;
    margin-top: -14px;
    border-radius: 10px !important;
}
.clone-btn:hover{
    box-shadow: 1px 1px 1px 0px #444;
}

.clone-icon{
    display: inline-flex;
    color: #3b95ee;
    font-size: 12px !important;
}

.clone-text{
    font-size: 12px;
    color: #3b95ee;
}
.delete-icon{
    display: inline-flex;
    color: red;
    font-size: 12px;
}

.delete-text{
    font-size: 12px;
    color: red;
}

.MuiAccordionSummary-content{
    margin: 0px;
}

.MuiAccordionSummary-root{
    min-height: 0px !important;
    padding: 0px 16px !important;
    display: inline-flex !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0 !important;
}

.MuiAccordionSummary-expandIconWrapper svg{
    color: #fff !important;
}

.NeatGridModal .MuiDialog-paper{
    min-width: 700px;
    max-width: 900px;
}
.PreviewModal .MuiDialog-paper{
    min-width: 700px;
    max-width: 100%;
}

.accordion-header {
    width: max-content;
    display: inline-block;
}

.accordion-button{
    padding: 0px !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after{
    background-image: url('../assets/images/down-arrow.png') !important;
    background-size: 17px !important;
}

.accordion-button::after {
    background-image: url('../assets/images/down-arrow.png') !important;
    background-size: 17px !important;
    margin-top: 5px;
}

.table-dashboard-custom th, .table-dashboard-custom td{
    text-align: left;
    padding: 10px 25px !important;
}

.accordion-items{
    width: 98%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.footer-buttons{
    flex-wrap: wrap;
}

.footer-buttons button{
    margin-bottom: 10px;
    margin-right: 10px;
}

.dashboard-table-head{

}

.dashboard-table-box{
    background: linear-gradient(195deg, #49a3f1, #1A73E8) !important;
    padding: 10px;
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);
}

.dashboard-table-box h6{
    color: #fff;
}

@media (min-width: 1300px){
    .bag-container thead tr th .MuiTextField-root {
        width: 110%;
    }
}
@media (max-width: 786px){
    .header-top{
        display: -webkit-box !important;
    }
    .breadcrumbs-custom {
        margin: 0px !important;
        flex-basis: 55%;
    }
    .lineItemToggleBtn{
        display: inline-block;
        float: right;
    }
    .lineItemToggleBtn svg{
        color: #0977bc;
        font-size: 16px !important;
    }
   .ck-button label span {
        font-size: 11px;
        padding: 3px 15px;
    }
    .accordion-items{
        width: 100%;
        margin: 0;
    }
    .accordion-items h6{
        font-size: 10px;
    }

    .table-heading-btn {
        margin-top: 13px;
    }
  
}

.icon-table-span{
    display: inline-block;
    margin: 4px 2px;
}

@media print
{
    body{
        transform: scale(1) !important;
    }
    #root { display: none; }
    .btn-print-hide {display: none; }
    .PreviewModal { display: block; transform: scale(1) !important;}
}